import { useState, useEffect } from 'react'
import { createClient } from '@sanity/client'
import Head from 'next/head'
import Script from 'next/script'
import { newLive, validateEmail, SERVER_URL } from '../../js'
import Image from 'next/image'
import {
  Footer,
  Header,
  TryEdworking,
  HubspotChat,
  Category,
  VideoPodcasts,
  LastArticles,
  TopBanner,
  EdworkingPromotion,
} from '../../js/components'
import { OrganizationSchema, WebPageSchema, BreadcrumbListSchema } from '../../js/schemas'
import path from 'path'
import fs from 'fs/promises' // or 'fs' if using callbacks

export const client = createClient({
  projectId: 'k9mx6lbx', //process.env.SANITY_PROJECT_ID,
  dataset: 'production', //process.env.SANITY_DATASET,
  useCdn: true,
  apiVersion: '2024-01-18', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})

const categories = ['startups', 'remotework', 'podcast', 'edtech', 'productivity', 'edworking']

// Function to fetch posts by category
async function fetchPostsByCategory(category, language) {
  const query = `
  *[_type == "post" && approved == true && category->title == $category && language == $language] | order(publishedAt desc)[0...4] {
    _id,
    "title": slug.current,
    "rawTitle": title,
    color,
    language,
    "category": category->title,
    "cover": cover.asset->url,
    "resume": summary,
    "author": author->{
      "firstname": name,
      "lastname": "",
    },
  "createdAt": publishedAt
  }
  `
  return client.fetch(query, { category, language })
}
async function fetchAllPosts(language) {
  // Fetch posts for each category
  const postsByCategoryPromises = categories.map((c) => fetchPostsByCategory(c, language))

  // Wait for all fetches to complete
  const postsByCategory = await Promise.all(postsByCategoryPromises)

  // Flatten the array of posts arrays into a single array
  const allPosts = postsByCategory.flat()

  return allPosts
}

export async function getStaticProps({ locale, locales }) {
  const res1 = await fetch(process.env.NEXT_PUBLIC_VENUSAUR, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      operationName: 'GetBlogsLanding',
      query: `query GetBlogsLanding($language: String) {getBlogsLanding(language: $language){id rawTitle color language title category cover resume author { firstname lastname } createdAt}}`,
      variables: { language: locale },
    }),
  })
  const data = await res1.json()

  const venusaurBlogs = data.data.getBlogsLanding.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  )
  const sanityBlogs = await fetchAllPosts(locale)
  const combinedBlogs = sanityBlogs.concat(venusaurBlogs)
  const blogs = combinedBlogs.reduce((acc, current) => {
    if (!acc.find((blog) => blog.title === current.title)) {
      acc.push(current)
    }
    return acc.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  }, [])

  if (!data || data.errors) {
    return {
      notFound: true,
    }
  }
  let content
  try {
    const filePath = path.join(process.cwd(), 'public', 'content', locale, 'blog.json')

    // Read the file
    const jsonData = await fs.readFile(filePath, 'utf8')
    content = JSON.parse(jsonData)
  } catch (error) {
    console.error('error', error)
  }
  return { props: { blogs, content, locale, locales } }
}

export default function Workspace({ blogs, content, locale, locales }) {
  const edworking = blogs.filter((b) => b.category === 'edworking')
  const podcast = blogs.filter((b) => b.category === 'podcast')
  const remotework = blogs.filter((b) => b.category === 'remotework')
  const productivity = blogs.filter((b) => b.category === 'productivity')
  const startups = blogs.filter((b) => b.category === 'startups')

  const [promotion, setPromotion] = useState(false)

  const onMouseOut = () => {
    const goon = localStorage.getItem('promotion')
    if (!goon) setPromotion(true)
    document.body.removeEventListener('mouseleave', onMouseOut)
  }

  useEffect(() => {
    setTimeout(function () {
      document.body.addEventListener('mouseleave', onMouseOut)
    }, 3000)
  }, [])

  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-166939348-2"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-166939348-2');
        `}
      </Script>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NRW96HD');
        `}
      </Script>
      <Script id="hotjar" strategy="afterInteractive">
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2080824,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>
      <OrganizationSchema />
      <WebPageSchema name={content.head.title} description={content.head.description} />
      <BreadcrumbListSchema
        items={[
          {
            name: 'Home',
            url: `https://edworking.com/${locale !== 'en' ? `${locale}/` : ''}`,
          },
          {
            name: 'Blog',
            url: `https://edworking.com/${locale !== 'en' ? `${locale}/` : ''}blog`,
          },
        ]}
      />
      <Head>
        <title>{content.head.title}</title>
        <meta itemProp="name" content={content.head.title} />
        <meta itemProp="description" content={content.head.description} />
        <meta name="description" content={content.head.description} />
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <meta itemProp="image" content={'/images/Blog.png'} />
        <meta property="og:url" content={`https://edworking.com/blog`} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image:secure_url" content={'/images/Blog.png'} />
        {locales.map((locale1, index) => (
          <link
            key={index}
            rel="alternate"
            hrefLang={locale1}
            href={`https://edworking.com${locale1 !== 'en' ? `/${locale1}` : ''}/blog`}
          />
        ))}
        <link rel="alternate" href={`https://edworking.com/blog`} hrefLang="x-default" />
        <link
          rel="canonical"
          href={`https://edworking.com/${locale !== 'en' ? `${locale}/` : ''}blog`}
          key="canonical"
        />
      </Head>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NRW96HD" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      <link itemProp="thumbnailUrl" href={'/images/Blog.png'} />
      <span itemProp="thumbnail" itemScope itemType="http://schema.org/ImageObject">
        <link itemProp="url" href={`https://edworking.com/blog`} />
      </span>
      <EdworkingPromotion promotion={promotion} content={content.exitbanner} />
      {/*<TopBanner content={content.topbanner} />*/}
      <Header content={content.header} topbanner={true} />
      <LastArticles articles={blogs.filter((b, i) => i < 3)} />
      <Category
        pagination={false}
        empty={content.empty}
        category={content.categories[0]}
        rawCategory={'startups'}
        articles={startups.filter((a) => a.cover).filter((_, i) => i < 4)}
      />
      <Category
        pagination={false}
        empty={content.empty}
        category={content.categories[1]}
        rawCategory={'remotework'}
        articles={remotework.filter((a) => a.cover).filter((_, i) => i < 4)}
      />
      {productivity.filter((a) => a.cover).length > 0 && (
        <Category
          pagination={false}
          empty={content.empty}
          category={content.categories[4]}
          rawCategory={'productivity'}
          articles={productivity.filter((a) => a.cover).filter((_, i) => i < 4)}
        />
      )}
      <Category
        pagination={false}
        empty={content.empty}
        category={content.categories[2]}
        rawCategory={'edworking'}
        articles={edworking.filter((a) => a.cover).filter((_, i) => i < 4)}
      />
      {podcast.filter((a) => a.cover).length > 0 && (
        <VideoPodcasts
          pagination={false}
          empty={content.empty}
          category={content.categories[3]}
          rawCategory={'podcast'}
          articles={podcast.filter((a) => a.cover)}
        />
      )}
      <TryEdworking content={content.endbanner} />
      <Footer content={content.footer} />
      <HubspotChat />
      <div id="livenoti" className="livenoti"></div>
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-eu1.hs-scripts.com/25992597.js"
      ></Script>
    </>
  )
}
